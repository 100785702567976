import React from "react";
import dynamic from "next/dynamic";

import CustomParser from "modules/CustomParser";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import SkeletonWithFade from "components/Loading/SkeletonWithFade.js";

import { makeStyles } from "tss-react/mui";

import {
  zeroPadding,
  pageTitle,
} from "../../assets/jss/nextjs-material-kit-pro";

const PaceCalculator = dynamic(() =>
  import("../../components/Calculators/PaceCalculator")
);
const AboutJson = dynamic(() => import("seo-modules/AboutJson.js"));
const SectionContact = dynamic(() =>
  import("pages-sections/dynamicPage/SectionContact.js")
);

const LandingPage = dynamic(() =>
  import("pages-sections/dynamicPage/LandingPage.js")
);

const SectionTeam = dynamic(() =>
  import("pages-sections/about/SectionTeam.js")
);

const useStyles = makeStyles()({
  zeroPadding,
  pageTitle,
});

export default function DynamicPageBody(props) {
  const { pageData, path, pageTransition } = props;
  const pageTypes = pageData?.pageTypes;
  const { classes } = useStyles();

  if (pageTypes?.nodes.some((pt) => pt.slug === "landing-page")) {
    return <LandingPage {...props} />;
  }
  let gridSizes = { md: 12 };
  const gridSizesColumns = { md: 4 };

  if (path === "/contact") {
    gridSizes = gridSizesColumns;
  }

  const title = pageData ? pageData.title : null;
  const content = pageData ? pageData.content : null;

  return (
    <GridContainer justifyContent="space-around">
      <GridItem {...gridSizes}>
        <h1 className={classes.pageTitle}>
          <SkeletonWithFade inProp={pageTransition}>{title}</SkeletonWithFade>
        </h1>
        <div>
          <SkeletonWithFade inProp={pageTransition} count={4}>
            <CustomParser content={content} />
          </SkeletonWithFade>
        </div>
      </GridItem>
      {path === "/about" && !pageTransition ? (
        <GridItem className={classes.zeroPadding} {...gridSizes}>
          <>
            <SectionTeam team={pageData.about.team} />
            {/* <SectionServices /> */}
          </>
        </GridItem>
      ) : path === "/contact" ? (
        <SectionContact />
      ) : (
        path === "/pace-calculator" && <PaceCalculator />
      )}
      {!pageTransition && (path === "/contact" || path === "/about") && (
        <AboutJson />
      )}
    </GridContainer>
  );
}
