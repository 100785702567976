
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from "react";

import { PageSeo } from "seo-modules/PageSeo.js";

import DynamicPageBody from "pages-sections/dynamicPage/DynamicPageBody.js";

const DynamicPage = (props) => {
  const { seo, ...rest } = props;

  return (
    <>
      <PageSeo
        seo={seo}
        pageTitle={props.pageData?.title}
        ogContent={props.pageData?.pageDetails?.ogContent}
      />
      <DynamicPageBody {...rest} />
    </>
  );
};

 async function _getStaticProps({ params }) {
  const uri = params.dynamicPage;
  console.log("[Build] | Fetching dynamic page: ", uri);

  const { default: basicPageFetcher } = await import(
    "modules/basic-pages/basicPageFetcher"
  );

  return basicPageFetcher(uri);
}

export async function getStaticPaths() {
  const { emptyStaticPathsForBypass, noPrerender } = await import(
    "modules/utils"
  );

  if (noPrerender) {
    return emptyStaticPathsForBypass;
  }

  const { default: staticDbLoader } = await import("apollo/staticDbLoader.js");
  const { DYNAMICPAGE_QUERY_PRERENDER } = await import(
    "graphql/basicPage.query.js"
  );

  const { pageItemsToPrerender } = await import(
    "settings/global-website-config"
  );

  const pageVariables = {
    pageItemsToPrerender,
  };
  const { data } = await staticDbLoader(
    DYNAMICPAGE_QUERY_PRERENDER,
    pageVariables
  );

  const paths = data.pages.nodes.map((page) => ({
    params: { dynamicPage: page.slug },
  }));

  console.log(
    "[Build] | Pre building the following",
    paths.length,
    "dynamic page paths:",
    paths
  );

  return {
    paths,
    fallback: false,
  };
}

export default DynamicPage;


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/[dynamicPage]',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  